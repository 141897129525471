<template>
  <div class="app">
    <div class="app-h">发票申请</div>
    <div class="app-xt">
      <div class="app-b">
        <!-- <el-card class="box-card"> -->
        <div class="b-bbc"></div>
        <!-- </el-card> -->
        <div class="app-c">
          <div class="input">
            <div class="inputadd">
              <div>
                <div class="input-flex">
                  <div class="flex-txt1">
                    投标企业名称： <span class="flex-txt">*</span>
                  </div>
                  <el-input
                    class="input-1"
                    placeholder="请填写企业名称"
                    v-model="input.biddername"
                    clearable
                  >
                  </el-input>
                </div>
                <div class="input-flex">
                  <div class="flex-txt1">
                    统一社会信用代码：<span class="flex-txt">*</span>
                  </div>
                  <el-input
                    class="input-1"
                    placeholder="请填写"
                    v-model="input.biddercode"
                    clearable
                  >
                  </el-input>
                </div>
                <div class="input-flex">
                  <div class="flex-txt1">
                    开票金额：<span class="flex-txt">*</span>
                  </div>
                  <el-input
                    class="input-1"
                    placeholder="请填写"
                    v-model="input.amount"
                    clearable
                  >
                  </el-input>
                </div>
                <div class="input-flex">
                  <div class="flex-txt1">
                    票据类型：<span class="flex-txt">*</span>
                  </div>
                  <div @click="bbci" class="asd-flex">
                    <el-radio v-model="radio" label="0">普票</el-radio>
                    <el-radio v-model="radio" label="1">专票</el-radio>
                  </div>
                </div>
                <div class="input-flex">
                  <div class="flex-txt1">
                    公司地址：<span :class="zhuan ? 'flex-txt' : 'flex-txt11'"
                      >*</span
                    >
                  </div>
                  <el-input
                    class="input-1"
                    placeholder="请填写"
                    v-model="input.address"
                    clearable
                  >
                  </el-input>
                </div>
                <div class="input-flex">
                  <div class="flex-txt1">
                    公司电话：
                    <span :class="zhuan ? 'flex-txt' : 'flex-txt11'">*</span>
                  </div>
                  <el-input
                    class="input-1"
                    placeholder="请填写"
                    v-model="input.phone"
                    clearable
                  >
                  </el-input>
                </div>
              </div>
              <div>
                <div class="input-flex">
                  <div class="flex-txt1">
                    开户行：<span :class="zhuan ? 'flex-txt' : 'flex-txt11'"
                      >*</span
                    >
                  </div>
                  <el-input
                    class="input-1"
                    placeholder="请填写"
                    v-model="input.bank"
                    clearable
                  >
                  </el-input>
                </div>
                <div class="input-flex">
                  <div class="flex-txt1">
                    账号：<span :class="zhuan ? 'flex-txt' : 'flex-txt11'"
                      >*</span
                    >
                  </div>
                  <el-input
                    class="input-1"
                    placeholder="请填写"
                    v-model="input.account"
                    clearable
                  >
                  </el-input>
                </div>
                <div class="input-flex">
                  <div class="flex-txt1">
                    电子邮箱：<span class="flex-txt">*</span>
                  </div>
                  <el-input
                    class="input-1"
                    placeholder="请填写"
                    v-model="input.email"
                    clearable
                  >
                  </el-input>
                </div>
                <div class="input-flex">
                  <div class="flex-txt1"></div>
                  <div class="flex-txtq">接受电子发票的邮箱</div>
                </div>
                <div class="input-flex">
                  <div class="flex-txt1">
                    联系电话：<span class="flex-txt">*</span>
                  </div>
                  <el-input
                    class="input-1"
                    placeholder="请填写"
                    v-model="input.mobile"
                    clearable
                  >
                  </el-input>
                </div>

                <div class="input-flex">
                  <div class="flex-txt1">
                    收件人姓名：<span :class="zhuan ? 'flex-txt' : 'flex-txt11'"
                      >*</span
                    >
                  </div>
                  <el-input
                    class="input-1"
                    placeholder="请填写"
                    v-model="input.contactname"
                    clearable
                  >
                  </el-input>
                </div>
                <div class="input-flex">
                  <div class="flex-txt1">
                    收件地址:
                    <span :class="zhuan ? 'flex-txt' : 'flex-txt11'">*</span>
                  </div>
                  <el-input
                    class="input-1"
                    placeholder="请填写"
                    v-model="input.contactaddress"
                    clearable
                  >
                  </el-input>
                </div>
                <div class="input-flex">
                  <div class="flex-txt1">
                    <span :class="zhuan ? 'flex-txt' : 'flex-txt11'"></span>
                  </div>
                  <div class="input-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="butt">
      <el-button type="text" @click="projects" class="button-i"
        >申请开票</el-button
      >
    </div>
  </div>
</template>

<script>
import { applyprogress, getUserInfo, invoicenoticepost } from "@/api/user";
// import "./js/modernizr.js";
// import "./js/main"
export default {
  data() {
    return {
      input: {
        appkey: "",
        timestamp: "",
        platformcode: "",
        applyno: "",
        biddercode: "",
        biddername: "",
        baohanno: "",

        amount: "",
        type: "",
        address: "",
        phone: "",
        bank: "",
        account: "",
        email: "",
        mobile: "",
        contactname: "",
        contactaddress: "",
        sign: "132",
      },
      tabPosition: "right",
      tab: "left",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      baohanno: "",
      applyno: "",
      zhuan: false,
      radio: "-1",
    };
  },
  created() {
    this.applyno = sessionStorage.getItem("applyno");
    this.input.applyno = sessionStorage.getItem("applyno");
    this.baohanno = sessionStorage.getItem("baohanno");
    this.input.baohanno = sessionStorage.getItem("baohanno");
    // console.log(bbc);
    this.bbca();
  },
  methods: {
    async projects() {
      this.input.type = this.radio;
      // this.input.baohanno= this.baohanno
      // this.$message({
      //   showClose: true,
      //   message: "发票申请成功",
      //   type: "success",
      // });
      var bbc = this.input;
      this.bbca();
      //  this.input.applyno= this.baohanno
      const { data } = await getUserInfo(bbc);
      if (data.msg == "发票申请成功") {
        this.$alert("发票申请成功,跳转金融机构", "提示", {
          confirmButtonText: "确定",
          customClass:"messageasd",
          callback: (action) => {
            this.invoicenoti(app);
            this.$router.push({
              name: "public",
              params: {},
            });
          },
        });
        return;
      } else {
        this.$alert(data.msg, "提示", {
          confirmButtonText: "确定",
           customClass:"messageasd",
          callback: (action) => {},
        });
      }
    },
    async invoicenoti(e) {
      const { data } = await invoicenoticepost(e);
      // console.log("发票通知",data);
    },
    bbci() {
      if (this.radio == 1) {
        this.zhuan = false;
        return;
      }
      if (this.radio == 0) {
        this.zhuan = true;
        return;
      }
    },
    async bbca() {
      var bbc = this.applyno;
      // console.log(bbc);
      // const {data} =await loginadd(bbc)
      const { data } = await applyprogress(bbc);
      // console.log(data);
      var bbc = data.data;
      if (bbc !== "") {
        this.input.appkey = bbc.appkey;
        this.input.timestamp = bbc.timestamp;
        this.input.platformcode = bbc.platformcode;
        this.input.applyno = bbc.applyno;
        this.input.biddercode = bbc.biddercode;
        this.input.biddername = bbc.biddername;
        this.input.baohanno = bbc.baohanno;
      }
    },
  },
};
</script>

<style scoped>
html {
  width: 100vw;
}
.app {
  /* padding: 0 20%; */
}

.flex-txt11 {
  color: #ffffff;
}
.inputadd {
  display: flex;
  justify-content: center;
}
.app-xt {
  padding: 5% 8%;
}
.b-bbc {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.uppder {
  padding-right: 12px;
}
.asd-flex {
  width: 200px;
  margin-left: 20px;
}
.input-flex {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.input-1 {
  width: 230px !important;
  width: 113px;
  /* height: 20px !important; */
}
.app-h {
  padding-left: 1.6875rem;
  /* width: 100%; */
  height: 2.6875rem;
  line-height: 2.6875rem;
  background: #1890ff;
  font-size: 1.0625rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
/deep/ .el-input__inner {
  /* margin-left: 14px !important;
  height: 27px !important; */
  margin-left: 14px !important;
  height: 33px !important;
  width: 92%;
}

.text {
  font-size: 14px;
}

.item {
  padding: 8px 0;
}

.box-card {
  width: 480px;
}
.flex-txt {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff0101;
}
.flex-txtq {
  margin-left: -80px;
  margin-top: -7px;
  color: #999999;
}
.flex-txt1 {
  /* font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 150px;
  text-align: left; */
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 200px;
  text-align: right;
}
.abc {
  padding-left: 24px;
}
.app-a {
  display: flex;
  margin-top: 1.75rem;
  position: relative;
}
.a-left {
  width: 50%;
  height: 3.0625rem;
  display: flex;
  line-height: 3.125rem;
  align-content: center;
  justify-content: center;
  color: #069cff !important;
  background: #cdebff;
  border: .0625rem solid #f0f3fb;
  border-right: 0;
  border-radius: .5625rem 0rem 0rem .5625rem;
  font-size: .8125rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.a-img {
  padding-top: .85rem;
  padding-right: .9375rem;
  width: 1.25rem;
  height: 1.25rem;
}
.aa-img {
  position: absolute;
  height: 48.96px;
  margin-top: .0625rem;
  padding: 0;
  left: 45%;
}
.a-right {
  display: flex;
  align-content: center;
  justify-content: center;
  line-height: 3.125rem;
  width: 50%;
  height: 3.0625rem;
  margin-top: 1px;

  background: #069cff;
  border-radius: .4375rem;
  font-size: .8125rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.app-b {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 2.8125rem;
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* padding: 0 30%; */
  /* align-content: space-around;
  justify-content: space-between; */
}
.app-c {
  width: 100%;
  margin-top: 1.875rem;
}
.app-b2 {
  display: flex;
  line-height: 1.5rem;
  font-size: .8125rem;
  width: 48%;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 1.625rem;
}
.b-txt {
  width: 18.5rem;
  height: 1.5rem;
  flex: 1;
  background: #f3f3f3;
  border: .0625rem solid #dedede;
  padding-left: .375rem;
  line-height: 1.5rem;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.c-img {
  width: 30%;
  height: 100%;
}
.c-txt {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.txt-1 {
  font-size: .8125rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-2 {
  margin-top: 1.0625rem;
  font-size: .75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-3 {
  margin-top: .75rem;
  font-size: .75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-4 {
  margin-top: .9375rem;
  font-size: .75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  display: flex;
}
.c-div {
  margin-top: .25rem;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
}
.butt {
  width: 100%;
  display: flex;
  justify-content: center;
}
.button-i {
  margin-top: 3rem;
  width: 13.75rem;
  height: 5.5%;
  padding: .3125rem 0;
  line-height: 1.875rem;
  border-radius: .5rem;
  background: #069cff;
  text-align: center;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fefefe;
}
/* 上传 */
</style>
<style >
.messageasd{
  width: 267px !important;
  margin-top: -15.875rem;
}
</style>